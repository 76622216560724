import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchWithAuth } from "../vendor/apiClient";

interface User {
  username: string;
  votesGivenPrePitchRound: number[]; // Array of idea IDs the user has voted for
  votesGivenPostPitchRound: number[]; // Array of idea IDs the user has voted for
  currentRound: Round;
  isAdmin: boolean;
}

type Round = "pre_pitch" | "pitch" | "post_pitch" | "closed";

interface UserContextProps {
  user: User | null;
  refreshUser: () => void;
  hasUserVotedFor: (ideaId: number) => boolean;
  prePitchVotesRemaining: () => number;
  postPitchVotesRemaining: () => number;
  getCurrentRound: () => Round;
  castVote: (ideaId: number) => void;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  function hasUserVotedFor(ideaId: number): boolean {
    const current_round = user?.currentRound;
    if (current_round === "pre_pitch") {
      return user?.votesGivenPrePitchRound.includes(ideaId) || false;
    } else if (current_round === "post_pitch") {
      return user?.votesGivenPostPitchRound.includes(ideaId) || false;
    } else {
      return false;
    }
  }

  function castVote(ideaId: number) {
    const current_round = user?.currentRound;
    if (current_round === "pre_pitch") {
      if (user?.votesGivenPrePitchRound.includes(ideaId)) {
        // If the user has already voted for this idea, remove their vote
        user.votesGivenPrePitchRound = user?.votesGivenPrePitchRound.filter(
          (id) => id !== ideaId
        );
      } else {
        user?.votesGivenPrePitchRound.push(ideaId);
      }
    } else if (current_round === "post_pitch") {
      // If the user has already voted for this idea, remove their vote
      if (user?.votesGivenPostPitchRound.includes(ideaId)) {
        user.votesGivenPostPitchRound = user?.votesGivenPostPitchRound.filter(
          (id) => id !== ideaId
        );
      } else {
        user?.votesGivenPostPitchRound.push(ideaId);
      }
    }
    setUser(user);
  }

  function getCurrentRound(): Round {
    return user?.currentRound || "pre_pitch";
  }

  function prePitchVotesRemaining(): number {
    const totalVotes = 10;
    return totalVotes - (user?.votesGivenPrePitchRound.length || 0);
  }

  function postPitchVotesRemaining(): number {
    const totalVotes = 3;
    return totalVotes - (user?.votesGivenPostPitchRound?.length || 0);
  }

  async function refreshUser() {
    try {
      const response = await fetchWithAuth("/me");
      const data: User = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        refreshUser,
        hasUserVotedFor,
        prePitchVotesRemaining,
        postPitchVotesRemaining,
        getCurrentRound,
        castVote,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
