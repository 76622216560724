import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { useUser } from "../contexts/UserContext";

interface IdeaProps {
  ideaId: number;
  ideaText: string;
  votes: number;
  onVote: () => void;
  creatorUsername?: string;
}

const IdeaCard: React.FC<IdeaProps> = ({
  ideaId,
  ideaText,
  votes,
  onVote,
  creatorUsername,
}) => {
  const { hasUserVotedFor, getCurrentRound } = useUser();

  return (
    <Card style={{ margin: "10px" }}>
      <CardContent>
        <Typography variant="h6">{ideaText}</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{votes} votes</Typography>
          {getCurrentRound() !== "pitch" ? (
            hasUserVotedFor(ideaId) ? (
              <Button onClick={onVote} variant="outlined" color="secondary">
                Unvote
              </Button>
            ) : (
              <Button onClick={onVote} variant="outlined" color="primary">
                Vote
              </Button>
            )
          ) : (
            creatorUsername
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default IdeaCard;
