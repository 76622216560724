import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import IdeaCard from "../components/IdeaCard";
import IdeaSubmissionModal from "../components/IdeaSubmissionModal";
import UserInfoBar from "../components/UserInfoBar";
import { useUser } from "../contexts/UserContext";
import { fetchWithAuth } from "../vendor/apiClient";

import { io } from "socket.io-client";

interface Idea {
  id: number;
  text: string;
  votes: number;
  creatorUsername: string;
}

const SubmissionVotingPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const { user, hasUserVotedFor, refreshUser, getCurrentRound, castVote } =
    useUser();

  async function sortIdeas(ideasToSort?: Idea[]) {
    let sortedIdeas: Idea[];
    if (ideasToSort === undefined) {
      sortedIdeas = ideas;
    } else {
      sortedIdeas = ideasToSort;
    }

    sortedIdeas.sort((a, b) => {
      if (getCurrentRound() !== "pitch") {
        const userVotedForA = hasUserVotedFor(a.id);
        // Prioritize the one user voted for
        return userVotedForA ? -1 : 1;
      } else {
        // Otherwise, prioritize the one with more votes
        return b.votes - a.votes;
      }
    });

    setIdeas(sortedIdeas);
  }

  async function getIdeas() {
    try {
      const response = await fetchWithAuth("/ideas");
      const data: Idea[] = await response.json();

      await sortIdeas(data);
    } catch (error) {
      console.error("Error fetching ideas:", error);
    }
  }

  const updateVoteCount = (ideaId: number, newVoteCount: number) => {
    setIdeas((prevIdeas) => {
      return prevIdeas.map((idea) => {
        if (idea.id === ideaId) {
          return { ...idea, votes: newVoteCount };
        }
        return idea;
      });
    });
  };

  const addNewIdea = (newIdea: Idea) => {
    setIdeas((prevIdeas) => [...prevIdeas, newIdea]);
  };

  useEffect(() => {
    const socket = io("https://gbh.digital", {
      path: "/api/socket.io",
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    socket.on("vote update", (data: { ideaId: number; voteCount: number }) => {
      updateVoteCount(data.ideaId, data.voteCount);
    });

    socket.on("round update", (data: { currentRound: string }) => {
      getIdeas();
      refreshUser();
    });

    socket.on("idea added", (newIdea: Idea) => {
      addNewIdea(newIdea);
    });

    // Cleanup the connection when the component is unmounted
    return () => {
      console.log("Disconnecting socket");
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    getIdeas();
  }, []);

  const handleAdvanceRound = () => {
    fetchWithAuth("/event/set_round", {
      method: "POST",
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    getIdeas();
    setOpenModal(false);
  };

  if (getCurrentRound() === "closed") {
    return (
      <div>
        <UserInfoBar />
        <h1>Voting is closed - thank you!</h1>
      </div>
    );
  }

  return (
    <div>
      <UserInfoBar />

      {ideas.map((idea) => (
        <IdeaCard
          key={idea.id}
          ideaId={idea.id}
          ideaText={idea.text}
          votes={idea.votes}
          onVote={() => {
            castVote(idea.id);
            sortIdeas(ideas);
            fetchWithAuth(`/ideas/${idea.id}/vote`, {
              method: "POST",
            }).catch((error) => {
              refreshUser();
              alert(error.message);
            });
          }}
          creatorUsername={idea.creatorUsername}
        />
      ))}

      {/* Normal users can submit ideas during the pre-pitch round */}
      {getCurrentRound() === "pre_pitch" && !user?.isAdmin && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#2FBFB7",
            }}
            onClick={handleOpenModal}
          >
            <AddIcon />
          </Fab>

          <IdeaSubmissionModal open={openModal} onClose={handleCloseModal} />
        </>
      )}

      {/* Admin users can advance the round */}
      {user?.isAdmin && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#2FBFB7",
            }}
            onClick={handleAdvanceRound}
          >
            <DoubleArrowIcon />
          </Fab>
        </>
      )}
    </div>
  );
};

export default SubmissionVotingPage;
