import React from "react";
import { Box, Typography, Badge, styled } from "@mui/material";
import { useUser } from "../contexts/UserContext";

const RoundIndicator: React.FC<{ round: string }> = ({ round }) => {
  const roundMapping: { [key: string]: number } = {
    pre_pitch: 1,
    pitch: 2,
    post_pitch: 3,
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#F259A5",
      color: "#fff",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      borderRadius: "50%",
      padding: "0 4px",
    },
  }));

  return (
    <StyledBadge
      badgeContent={roundMapping[round]}
      color="error"
      overlap="circular"
    />
  );
};

const UserInfoBar: React.FC = () => {
  const {
    user,
    prePitchVotesRemaining,
    postPitchVotesRemaining,
    getCurrentRound,
  } = useUser();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={1}
      bgcolor="#2FBFB7"
      color="white"
    >
      <Typography variant="h6">{user?.username}</Typography>
      <Box display="flex" alignItems="center">
        <Box marginRight={2}>
          <RoundIndicator round={getCurrentRound()} />
        </Box>
        {getCurrentRound() !== "pitch" ? (
          <Typography marginLeft={1}>
            {getCurrentRound() === "pre_pitch"
              ? prePitchVotesRemaining()
              : postPitchVotesRemaining()}{" "}
            votes left
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default UserInfoBar;
