import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";

import { UserProvider } from "./contexts/UserContext";
import SubmissionVotingPage from "./pages/SubmissionVotingPage";
import { Typography } from "@mui/material";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedPassword = localStorage.getItem("password");
    const params = new URLSearchParams(window.location.search);
    const username = params.get("u");
    const password = params.get("p");

    if (username && password) {
      if (username && password) {
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        setIsLoggedIn(true);
      }
    } else if (storedUsername && storedPassword) {
      setIsLoggedIn(true);
    }
  }, []);

  if (!isLoggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Typography variant="h4" component="h1" gutterBottom>
          Please use your login link or QR code to access the voting platform.
        </Typography>
      </ThemeProvider>
    );
  }

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SubmissionVotingPage />
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
