const BASE_URL = "https://gbh.digital/api";

async function fetchWithAuth(
  endpoint: string,
  options: RequestInit = {},
): Promise<Response> {
  const storedUsername = localStorage.getItem("username");
  const storedPassword = localStorage.getItem("password");

  if (!storedUsername || !storedPassword) {
    throw new Error("No stored credentials found");
  }

  // Create the Basic Authentication header
  const authString = `${storedUsername}:${storedPassword}`;
  const encodedAuthString = btoa(authString);

  // Setting up headers
  const headers = new Headers(options.headers || {});
  headers.set("Authorization", `Basic ${encodedAuthString}`);
  headers.set("Content-Type", "application/json");

  // Making the actual fetch request
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    ...options,
    headers,
  });
  if (response.status == 403) {
    localStorage.removeItem("username");
    localStorage.removeItem("password");
  }

  // Error handling: can be expanded upon as needed
  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.message || "Something went wrong");
  }

  return response;
}

export { fetchWithAuth };
