import React from "react";
import {
  Modal,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { fetchWithAuth } from "../vendor/apiClient";

interface Props {
  open: boolean;
  onClose: () => void;
}

const IdeaSubmissionModal: React.FC<Props> = ({ open, onClose }) => {
  const [idea, setIdea] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleSubmit() {
    if (!idea.trim()) {
      // Check if idea is only whitespace or empty
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetchWithAuth("/ideas", {
        method: "POST",
        body: JSON.stringify({ text: idea }),
      });

      if (response) {
        // You can handle successful submission here, e.g., show a toast message
        setIdea(""); // Clear the idea field
        onClose(); // Close the modal
      }
    } catch (error) {
      // Handle any errors, e.g., show an error toast or alert
      console.error("Error submitting idea:", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          top: "0%",
          left: "0%",
          height: "100%",
          width: "100%",
          position: "absolute",
          backgroundColor: "#121212", // Dark background
          padding: "20px",
          boxSizing: "border-box",
          overflowY: "auto",
        }}
      >
        <IconButton
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            color: "white",
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom style={{ color: "white" }}>
          Submit Your Idea
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={idea}
          onChange={(e) => setIdea(e.target.value)}
          inputProps={{
            maxLength: 115, // Set the maximum length
          }}
          helperText={`${idea.length}/115`} // Show the current length
          FormHelperTextProps={{
            style: {
              color:
                idea.length > 100
                  ? "red"
                  : idea.length > 85
                  ? "orange"
                  : "white",
            },
          }}
        />
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </div>
    </Modal>
  );
};

export default IdeaSubmissionModal;
